import { render, staticRenderFns } from "./work-allocation.vue?vue&type=template&id=327c4b1c&scoped=true"
import script from "./work-allocation.vue?vue&type=script&lang=js"
export * from "./work-allocation.vue?vue&type=script&lang=js"
import style0 from "./work-allocation.vue?vue&type=style&index=0&id=327c4b1c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "327c4b1c",
  null
  
)

export default component.exports